import { PUBLIC_DEPLOYMENT_ENV, PUBLIC_SENTRY_DSN } from '$env/static/public'
import * as Sentry from '@sentry/sveltekit'
import { BrowserTracing, Replay, handleErrorWithSentry } from '@sentry/sveltekit'

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Replay(), new BrowserTracing()],

  environment: PUBLIC_DEPLOYMENT_ENV,
  enabled: PUBLIC_DEPLOYMENT_ENV !== 'local',
})

export const handleError = handleErrorWithSentry()
